/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import ExpandableInfoParagraph from "./ExpandableInfoParagraph/ExpandableInfoParagraph";
import { styles } from "./AboutUs.styles";
import sliderImage1 from '../../../assets/imgs/slider_img_1.png';
import sliderImage2 from '../../../assets/imgs/slider_img_2.png';
import sliderImage3 from '../../../assets/imgs/slider_img_3.png';
import aboutTheCompany from '../../../assets/imgs/about_the_company_1.png';
import LogoPurple from '../../../assets/imgs/logomark_purple.svg'
import ResourcesIcon1 from '../../../assets/icons/resources_1_icon.svg'
import ResourcesIcon2 from '../../../assets/icons/resources_2_icon.svg'
import ResourcesIcon3 from '../../../assets/icons/resources_3_icon.svg'
import ButtonSecondary from "../../../design/button-secondary/ButtonSecondary";
import { PageBlockId, Paths } from "../../../shared/constants";
import { useAppNavigation } from "../../../context/AppNavigationContext";

const imagePaths = [sliderImage1, sliderImage2, sliderImage3];

interface AboutUsProps {
}

const AboutUs: React.FC<AboutUsProps> = (props) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const { t } = useTranslation();
  const { scrollToBlock } = useAppNavigation();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImageIndex((prev) => prev === imagePaths.length - 1 ? 0 : prev + 1);
    }, 5000)

    return () => clearInterval(interval);
  }, []);

  return (
    <section id={PageBlockId.aboutUs} css={styles.aboutUsWrapper}>
      <div css={styles.headerWrapper}>
        <h1>
          {t("aboutUsPage.title")}
        </h1>
        <div/>
        <p>
          {t("aboutUsPage.subtitle")}
        </p>
      </div>
      <div css={styles.sliderWrapper}>
        {imagePaths.map((path, index) =>
          <img
            css={css(styles.sliderImage, index === activeImageIndex && styles.activeSliderImage)}
            src={imagePaths[index]}
            alt={"About Us slider " + (index + 1)}
            key={index}
          />)
        }
        <div css={styles.sliderIndexContainer}>
          {imagePaths.map((path, index) =>
            <div
              css={css(styles.sliderIndex, index === activeImageIndex && styles.sliderActiveIndex)}
              key={index}
            />
          )}
        </div>
        <div css={styles.sliderContentWrapper}>
          <div css={styles.logoPurple}>
            <LogoPurple/>
          </div>
          <div css={styles.sliderTextInfoWrapper}>
            <h3>
              {t("aboutUsPage.sliderTitle")}
            </h3>
            <p>
              {t("aboutUsPage.sliderDescription")}
            </p>
            <ButtonSecondary
              css={styles.secondaryRoundButton}
              onClick={()=> scrollToBlock(Paths.home, PageBlockId.projects)}
            > {t("aboutUsPage.seeOurProjectsButton")}</ButtonSecondary>
          </div>
        </div>
      </div>
      <div css={styles.aboutTheCompanyWrapper}>
        <h5 css={styles.aboutTheCompanyHeader}>{t("aboutUsPage.aboutTheCompanyTitle")}</h5>
        <div css={styles.aboutTheCompanyInfo}>
          <div>
            <h2>{t("aboutUsPage.differentFromOtherTitle")}</h2>
            <p>{t("aboutUsPage.differentFromOtherDescription")}</p>
            {/*<ButtonSecondary*/}
            {/*  onClick={()=> scrollToBlock(Paths.home, PageBlockId.aboutUs)}*/}
            {/*  css={styles.secondaryRoundButton}>{t("aboutUsPage.moreAboutUsButton")}</ButtonSecondary>*/}

          </div>
          <img
            src={aboutTheCompany}
            alt={"About the company"}
          />
        </div>
      </div>
      <div css={styles.takingResourcesRisksWrapper}>
        <h5>{t("aboutUsPage.takingResourcesRisksTitle")}</h5>
        <div css={styles.takingResourcesRisksTitleUnderline}/>
        <div css={styles.takingResourcesRisksContainer}>
          <div>
            <h1>{t("aboutUsPage.whatWeOfferTitle")}</h1>
            <p>{t("aboutUsPage.whatWeOfferDescription")}</p>
          </div>
          <div>
            <ExpandableInfoParagraph
              icon={<ResourcesIcon1/>}
              defaultExpanded
              title={t("aboutUsPage.strongExpertiseTitle")}
              infoValue={t("aboutUsPage.strongExpertiseDescription")}/>
            <ExpandableInfoParagraph
              icon={<ResourcesIcon2/>}
              title={t("aboutUsPage.remarkableQualityTitle")}
              infoValue={t("aboutUsPage.remarkableQualityDescription")}/>
            <ExpandableInfoParagraph
              icon={<ResourcesIcon3/>}
              title={t("aboutUsPage.solidPartnershipTitle")}
              infoValue={t("aboutUsPage.solidPartnershipDescription")}/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;