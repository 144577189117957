import { css } from '@emotion/react';
import theme from "../../../styles/theme";

export const styles = {
  aboutUsWrapper: css`
  `,
  headerWrapper: css`
    padding: 48px 32px 32px;
    display: flex;
    gap: 32px;
    color: ${theme.palette.primary.main};
    flex-wrap: wrap;

    & > h1 {
      flex: 7;
      min-width: 100%;
      @media (min-width: ${theme.breakpoints.values.sm}px) {
        padding-right: 30%;
      }

      @media (min-width: ${theme.breakpoints.values.md}px) {
        min-width: unset;
        padding-right: 0;
      }
    }

    & > div {
      flex: 2;
      margin-top: 32px;
      border-top: 1px solid ${theme.palette.primary.main}
    }

    & > p {
      flex: 4;
      font: normal normal 300 24px/30px Outfit;
      letter-spacing: 0.24px;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      padding: 54px 140px 82px 70px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 54px 234px 82px 136px;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      flex-wrap: nowrap;
      & > p {
        flex: 3;
      }
    }
  `,
  sliderWrapper: css`
    position: relative;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      /*margin-right: 44px;*/
    }
  `,
  sliderImage: css`
    min-height: 577px;
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    left:0;
    right:0;
    opacity: 0;
    transition: opacity 0.5s ease;

    @media (min-width: ${theme.breakpoints.values.md}px) {

      object-position: left;
    }
  `,
  activeSliderImage: css`
    opacity: 1;
    position: relative;
  `,
  sliderIndexContainer: css`
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  `,
  sliderIndex: css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${theme.palette.background.default};
  `,
  sliderActiveIndex: css`
    background: ${theme.palette.secondary.main}
  `,
  sliderContentWrapper: css`
    position: absolute;
    top: 0;
    right: 15%;
    left: 15%;
    height: 100%;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      max-width: 340px;
      width: 100%;
      left: unset;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      right: 160px;
      left: unset;

    }
  `,
  logoPurple: css`
    position: absolute;
    top: -35px;
    left: 0;
    display: none;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: block;
    }
  `,
  sliderTextInfoWrapper: css`
    position: absolute;
    left: 0;
    bottom: 32px;
    max-width: 340px;
    width: 100%;
    color: ${theme.palette.background.default};

    @media (min-width: ${theme.breakpoints.values.md}px) {
      top: 200px;
      bottom: unset;
    }
  }

  & p {
    margin: 16px 0;
    font: normal normal 300 14px/20px Outfit;
  }
  `,
  secondaryRoundButton: css`
    margin-top: 16px;
  `,
  aboutTheCompanyWrapper: css`
    padding: 50px 0 0;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 70px 70px 75px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 70px 140px 75px;
    }
  `,
  aboutTheCompanyHeader: css`
    /*width: 100%;*/
    border-bottom: 1px solid ${theme.palette.primary.main};
    text-transform: uppercase;
    color: ${theme.palette.primary.main};
    margin-left: 50px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding-left: unset;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: unset;
    }
  `,
  aboutTheCompanyInfo: css`
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;

    & > img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      margin-top: 28px;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        max-width: 50%;
        margin-top: unset;
      }
    }

    & > div {
      width: 380px;
      padding-right: 48px;
      padding-left: 48px;

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        padding-right: 96px;
      }

      @media (min-width: ${theme.breakpoints.values.xl}px) {
        padding: 0 96px;
      }

      @media (min-width: ${theme.breakpoints.values.md}px) {
        width: 100%;
        margin-left: unset;
      }

      & > h2 {
        color: ${theme.palette.primary.main}
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      flex-wrap: nowrap;

      & > * {
        flex: 1;
      }
    }
  `,
  //can be improved
  takingResourcesRisksWrapper: css`
    padding: 70px 48px;
    background: linear-gradient(to top right,
    #9C88FF 0%,
    #9C88FF 2%,
    #B1A6FF 4%,
    #C3B5FF 6%,
    #FAFAFA 8%,
    #FAFAFA 92%,
    #C3B5FF 94%,
    #B1A6FF 96%,
    #9C88FF 98%,
    #9C88FF 100%);

    & > h5 {
      text-transform: uppercase;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 70px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 92px 140px;
    }
  `,
  takingResourcesRisksTitleUnderline: css`
    width: 32px;
    border-top: 1px solid ${theme.palette.primary.main};
  `,
  takingResourcesRisksContainer: css`

    & > div:first-child {
      flex: 2;
      margin-top: 24px;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        margin-top: 96px;
      }
      
    }

    & > div:last-child {
      flex: 3;
      margin-top: 32px;

    }

    & h1 {
      color: ${theme.palette.primary.main};
    }

    & h2 {
      color: ${theme.palette.primary.main};
      text-transform: uppercase;
    }

    & > div:first-child > p {
      margin-top: 12px;
      font: normal normal 300 24px/30px Outfit;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      gap: 42px;
      display: flex;
    }
    
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      gap: 120px;
    }
    
    @media (min-width: ${theme.breakpoints.values.xl}px) {
      gap: 240px;
    }
  `
}