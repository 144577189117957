import React, { useEffect, useState } from 'react';
import i18n from 'i18next';

import { availableLanguages, defaultLanguage, Language } from "../shared/constants";

const AppGlobalContext = React.createContext({
  currentLanguage: defaultLanguage,
  nexLanguage: availableLanguages[1],
  setLanguage: (l: Language) => {}
});
const localStorageCurrentLanguageKey = 'btt-current-language'

interface AppGlobalProviderProps {
  children: React.ReactNode
}

function AppGlobalProvider(props: AppGlobalProviderProps) {
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem(localStorageCurrentLanguageKey) as Language || defaultLanguage);
  const [nexLanguage, setNexLanguage] = useState(() => {
    const langIndex = availableLanguages.findIndex((l) => l === currentLanguage);

    return availableLanguages[langIndex >= availableLanguages.length ? 0 : langIndex + 1];
  });

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    setNexLanguage(() => {
      const langIndex = availableLanguages.findIndex((l) => l === currentLanguage);

      return availableLanguages[langIndex >= availableLanguages.length-1 ? 0 : langIndex + 1];
    })
  }, [currentLanguage])


  function setLanguage(language: Language) {
    setCurrentLanguage(language);
    localStorage.setItem(localStorageCurrentLanguageKey, language);
  }

  return (
    <AppGlobalContext.Provider
      value={{
        currentLanguage,
        nexLanguage,
        setLanguage
      }}>
      {props.children}
    </AppGlobalContext.Provider>
  );
}

function useAppGlobal() {
  const context = React.useContext(AppGlobalContext);
  if (context === undefined) {
    throw new Error('useAppGlobal must be used within an AppGlobalProvider');
  }

  return context;
}

export { useAppGlobal, AppGlobalProvider };
