import { createTheme, ThemeOptions } from '@mui/material';

import palette from "./palette";

const theme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    //fontFamily: '',
  },
  palette,
} ;

export default createTheme(theme);


