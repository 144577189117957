import { css } from '@emotion/react';
import theme from "../../styles/theme";

export const styles = {
  button: css`
    color: ${theme.palette.text.primary};
    font: normal normal normal 12px/15px Outfit;
    transition: color 0.2s linear;

    &:hover{
      background-color: unset;
      color: ${theme.palette.primary.main};
      text-decoration: underline;
    }
  `,
  active: css`
    color: ${theme.palette.primary.main};
  `
};