import { css } from '@emotion/react';
import theme from "../../../styles/theme";

export const styles = {
  projectsWrapper: css`

  `,
  projectsHeaderWrapper: css`
    padding: 50px 0 50px 50px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 70px 70px 90px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 70px 140px 90px;
    }
  `,
  projectsHeader: css`
    width: 100%;
    border-bottom: 1px solid ${theme.palette.primary.main};
    text-transform: uppercase;
    color: ${theme.palette.primary.main}
  `,
  projectsInfo: css`
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding-top: 70px;
      display: flex;

      & > h2 {
        margin: unset !important;
        min-width: 400px;


        @media (min-width: ${theme.breakpoints.values.lg}px) {
          min-width: 538px;
        }

        @media (min-width: ${theme.breakpoints.values.xl}px) {
          min-width: 530px;
        }
      }

      & > p {
        margin: unset;
      }
    }

    & > h2 {
      color: ${theme.palette.primary.main};
      margin: 32px 0;
    }

    & > p {
      font: normal normal 300 24px/30px Outfit;
      margin: 16px 32px 16px 0 !important;
    }
  `,
  projectsContainer: css`
    display: flex;

    & > button:first-child {
      margin: 300px 8px 0;
      height: 32px;
    }

    & > button:last-child {
      margin: 300px 8px 0;
      height: 32px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 0 48px;

      & > button:first-child {
        margin-right: 48px;
      }

      & > button:last-child {
        margin-left: 48px;
      }
    }
  `,
  projectWrapper: css`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 100px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    & > img {
      max-width: 100%;
    
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      max-width: unset;      
    }
    

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      & > img {
        width: 474px;
        height: 637px;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: row;
      align-items: unset;
      gap: 64px;
    }
  `,
  projectWrapperFadeOut: css`
    opacity: 0;
  `,
  projectNumber: css`
    position: absolute;
    top: 32px;
    left: calc(max((100% - 474px) / 2 - 32px, -32px));
    color: ${theme.palette.primary.main};
    font: normal normal normal 100px/60px Outfit;

    & > span {
      font: normal normal bold 100px/60px Outfit;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      left: -32px;
    }
  `,
  projectDataWrapper: css`
    padding-top: 32px;
  `,
  projectDataHeader: css`
    font: normal normal 300 12px/18px Outfit;
    color: ${theme.palette.primary.main};

    & > p {
      margin: 0
    }
  `,
  projectDataHeaderUnderline: css`
    width: 32px;
    margin-top: 16px;
    border-top: 1px solid ${theme.palette.primary.main};
  `,
  projectDescriptionWrapper: css`
    padding: 16px 0 0;

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 48px 64px 0;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      padding: 48px 0 0;
    }

    & > h2, & > h4 {
      color: ${theme.palette.primary.main};
    }

    & > h4 {
      margin-top: 16px;
    }

    & > p {
      margin: 0;

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        margin: 0 92px 0 0;
      }

      font: normal normal 300 14px/20px Outfit;
    }
  `,
  buttonContainer: css`
    display: flex;
    justify-content: center;
    margin-top: 48px;
    width: 100%;
  `,
}