/** @jsxImportSource @emotion/react */
import React, { MouseEventHandler } from "react";
import { Button } from "@mui/material";

import { styles } from "./ButtonSecondary.styles";
import { css as cssMerge, SerializedStyles } from "@emotion/react";

interface ButtonSecondaryProps {
  children?: React.ReactNode;
  css?: SerializedStyles;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = (props) => {
  const {children, css, onClick} = props;

  return (
    <Button
      onClick={onClick}
      css={cssMerge(styles.button, css)}
      variant="contained"
      color="secondary">
      {children}
    </Button>

  );
};

export default ButtonSecondary;