import { css } from '@emotion/react';
import theme from "../../styles/theme";

export const styles = {
  button: css`
    border-radius: 18px;
    text-transform: unset;
    font: normal normal normal 12px/25px Outfit;
    transition: background-color 0.2s linear;
    
    &:hover{
      background-color: ${theme.palette.primary.main};
    }
  `,
};