import { css } from '@emotion/react';
import theme from "../../../styles/theme";

export const styles = {
  teamsWrapper: css`
  `,
  teamsHeaderWrapper: css`
    padding: 50px 0 24px 50px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 70px 70px 0 70px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 70px 140px 0;
    }

    & > h5 {
      width: 100%;
      border-bottom: 1px solid ${theme.palette.primary.main};
      text-transform: uppercase;
      color: ${theme.palette.primary.main};
      margin-bottom: 58px;
    }

    & > h2 {
      color: ${theme.palette.primary.main}
    }
  `,
  teamsContainer: css`
    padding: 0 50px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 58px 70px 0;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 58px 140px 0;
    }
  `,
  teamContainer: css`
    flex: 1;
    padding: 12px 0;

    & > h3 {
      margin-top: 40px;
    }

    & > p {
      margin-top: 24px;
    }

    &:last-child {
      padding-bottom: 0;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        padding-bottom: 12px;
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 12px;
    }
  `,
  teamImageContainer: css`
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      width: unset;
      display: unset;
    }
  `,
  technologiesWeOfferWrapper: css`
    padding: 64px 0 0;
    width: 100%;
    position: relative;

    & > h1 {
      right: 70px;
      top: 128px;
      color: ${theme.palette.primary.main};
      padding: 0 70px 0;
      margin-bottom: 20px;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        padding: 0;
      }

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        position: absolute;
      }

      @media (min-width: 1520px) {
        left: 924px;
        right: 60px;
        white-space: nowrap;
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 64px 50px 0;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 64px 140px 0;
    }
  `,
  technologiesWeOfferContainer: css`
    display: flex;
    flex-direction: column;
    gap: 48px;

    & > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      max-width: 721px;

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        width: calc(100% - 364px);
      }
    }

    & > p {
      font: normal normal 300 14px/20px Outfit;
      max-width: 500px;
      margin-top: 0px;
      padding: 0 50px 0;

      @media (min-width: ${theme.breakpoints.values.sm}px) {
      }

      @media (min-width: ${theme.breakpoints.values.md}px) {
        padding: 0;
      }

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        margin-top: 164px;
        max-width: 300px;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      gap: 64px;
      flex-direction: row;

    }
  `,
  technologiesGroupWrapper: css`
    display: flex;
    padding: 44px 50px 0;


    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 64px 140px 0;
    }
  `
  ,
  technologiesGroupNamesWrapper: css`
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      flex: 1;
      margin-right: 48px;
    }

  `,
  technologiesGroupName: css`
    width: 100%;
    cursor: pointer;
    font: normal normal 300 24px/30px Outfit;
    border-bottom: 1px solid transparent;
    transition: 0.2s linear color, 0.2s linear border-bottom-color;
    padding: 8px 0;
    margin: 8px 0;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin: 0;
    }
  `,
  technologiesGroupNameSelected: css`
    color: ${theme.palette.secondary.main};
    border-bottom: 1px solid ${theme.palette.secondary.main};
  `,
  technologiesWrapper: css`
    display: none;
    
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      max-width: 50%;
      gap: 32px;
    }
  `,
  // technologyWrapper: css`
  //   width: 150px;
  //   height: 150px;
  //   min-width: 150px;
  //   border-radius: 50%;
  //   border: 1px solid ${theme.palette.primary.main};
  //   font: normal normal 600 14px/20px Outfit;
  //   color: ${theme.palette.primary.main};
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 16px;
  //   text-align: center;
  //   transition: opacity 0.2s linear, transform 0.2s linear;
  // `,

  technologiesSmallWrapper: css`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    padding: 32px 0;
    transition: opacity 0.2s linear, transform 0.2s linear;

    // @media (min-width: ${theme.breakpoints.values.lg}px) {
    //   display: none;
    // }
  `,
  technologySmallWrapper: css`
    width: 110px;
    height: 110px;
    min-width: 110px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.primary.main};
    font: normal normal 600 14px/20px Outfit;
    color: ${theme.palette.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    text-align: center;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      width: 150px;
      height: 150px;
      min-width: 150px;
      padding: 16px;
      transition: opacity 0.2s linear, transform 0.2s linear;
    }
  `,
  technologiesWrapperFadeOut: css`
    opacity: 0;
  `,
}