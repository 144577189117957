/** @jsxImportSource @emotion/react */
import React from "react";

import PageContainer from "../../layout/page-container/PageContainer";
import { styles } from "./Projects.styles";

interface ProjectsProps {
}

const Projects: React.FC<ProjectsProps> = (props) => {

  return (
    <PageContainer>
      <section css={styles.projectsPageWrapper}>
        <h1>Nice site with all projects in the future:)</h1>
      </section>

    </PageContainer>
  );
};

export default Projects;