/** @jsxImportSource @emotion/react */
import React, { MouseEventHandler } from "react";
import { Button } from "@mui/material";
import { css, SerializedStyles } from "@emotion/react";

import { styles } from "./ButtonText.styles";

interface ButtonTextProps {
  children?: React.ReactNode;
  styles?: SerializedStyles;
  active?: boolean;
  disableRipple?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ButtonText: React.FC<ButtonTextProps> = (props) => {
  const { children, styles: userStyles, active, disableRipple, onClick } = props;

  return (
    <Button
      onClick={onClick}
      disableRipple={disableRipple}
      css={css(styles.button, userStyles, active && styles.active)}
      variant="text">
      {children}
    </Button>

  );
};

export default ButtonText;