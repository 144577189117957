import { css } from '@emotion/react';
import theme from "../../styles/theme";

export const styles = {
  footerWrapper: css`
    background: #2C2C39 0% 0% no-repeat padding-box;
    padding: 80px 50px 128px;
    position: relative;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 80px 70px 20px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 80px 140px 20px;
    }
  `,
  footerBackgroundWrapper: css`
    position: absolute;
    bottom: 0;
    right: 0;
    min-height: 320px;
    overflow: hidden;
    width: 100%;
  `,
  footerBackground: css`
    position: absolute;
    min-height: 320px;
    width: 100%;
    object-fit: cover;
    object-position: right;
  `,
  footerContent: css`
    position: relative;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      display: flex;
      justify-content: space-between;
    }
  `,
  footerLeftContent: css`
    flex: 1;

    & > svg {
      position: absolute;
      top: -180px;
      left: -26px;
      transform: scale(0.5);

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        top: -164px;
      }
    }

    & > p {
      padding: 0 0 32px;
      font: normal normal 300 18px/23px Outfit;
      color: ${theme.palette.text.secondary};
      max-width: 300px;
      
      @media (min-width: ${theme.breakpoints.values.lg}px) {
        padding: 0 0 100px;
      }
    }
  `,
  footerPolicy: css`
    position: relative;

    & > span:first-child {
      display: block;
      color: ${theme.palette.primary.main};
      font: normal normal bold 14px/20px Outfit;

      @media (min-width: ${theme.breakpoints.values.xl}px) {
        display: unset;
      }
    }

    & > a {
      color: ${theme.palette.text.secondary};
      font: normal normal 300 12px/20px Outfit;

      &:hover {
        cursor: pointer;
        color: ${theme.palette.primary.main};
      }
    }
  `,
  footerPolicyDivider: css`
    color: ${theme.palette.text.secondary} !important;
    font: normal normal 300 12px/20px Outfit !important;
    margin: 0 16px 0 0;

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      margin: 0 16px 0 32px;

    }
  `,
  footerRightContent: css`
    display: flex;
    justify-content: space-between;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        width: 150px;
      }


      @media (min-width: ${theme.breakpoints.values.xl}px) {
        width: 200px;
      }

      & > h4 {
        color: ${theme.palette.primary.main}
      }

      & > button {
        color: ${theme.palette.text.secondary};
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        justify-content: flex-start;
        font: normal normal 300 12px/20px Outfit;
      }

      & > h4 {
        margin-bottom: 8px;
      }
    }
  `
};