import { css } from '@emotion/react';
import theme from "../../styles/theme";

export const styles = {
  projectsPageWrapper: css`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    
    & > h1 {
      max-width: 620px;
      color: ${theme.palette.primary.main};

    }
  `
};