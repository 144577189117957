import { css } from '@emotion/react';
import theme from "../../styles/theme";

export const styles = {
  headerWrapper: css`
    //max-width: 1440px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
  `,
  headerTopInfo: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    font: normal normal 300 14px/20px Outfit;
    padding: 0 20px;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      justify-content: center;
    }
  `,
  briefUsButton: css`
    padding: 0;
    margin-left: 8px;
    text-decoration: underline;
    font: normal normal medium 14px/20px Outfit;
    text-transform: unset;
    transition: color 0.2s linear;

    &:hover {
      color: ${theme.palette.secondary.main};
      background: unset;
      text-decoration: underline;
    }

  `,
  headerPanelWrapper: css`
    display: flex;
    background: ${theme.palette.background.default};
    height: 80px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 24px 0 48px;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      padding: 0 44px 0 86px;

    }
  `,
  navigationWrapper: css`
    display: flex;
    gap: 32px
  `,
  navigationButton: css`
    display: none;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: unset;
    }
  `,
  menuAndLanguageWrapper: css`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (min-width: ${theme.breakpoints.values.sm}px) {
      gap: 28px;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }
  `,
  menuIconButton: css`
    height: 36px;
    width: 36px;
  `,
  languageButton: css`
    min-width: unset;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font: normal normal normal 12px/15px Outfit;
    color: ${theme.palette.background.default};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  menuModal: css`
    overflow: auto;
  `
};