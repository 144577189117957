import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import * as en from "./en.json";
import * as pl from "./pl.json";
import {Language} from "../shared/constants";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {...en}
    },
    pl: {
      translation: {...pl}
    },
  },
  lng: Language.en,
});