/** @jsxImportSource @emotion/react */
import React from "react";

import Header from "../header/Header";
import { styles } from "./PageContainer.styles";
import { css , SerializedStyles } from "@emotion/react";


interface PageContainerProps {
  children: React.ReactNode;
  customCss?: SerializedStyles;
}

const PageContainer: React.FC<PageContainerProps> = (props) => {
  const { children,  customCss } = props;

  return (
    <main css={styles.pageWrapper}>
      <Header/>
      <div css={css(styles.pageContainerWrapper, customCss)}>
        {children}
      </div>
    </main>
  );
};

export default PageContainer;