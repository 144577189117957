import React from 'react';
import { ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import Router from "./router";
import './styles/global.css';
import { AppGlobalProvider } from "./context/AppGlobalContext";


function App() {
  return (
    <AppGlobalProvider>
      <ThemeProvider theme={theme}>
        <Router/>
      </ThemeProvider>
    </AppGlobalProvider>
  );
}

export default App;
