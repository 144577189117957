/** @jsxImportSource @emotion/react */
import React from "react";
import { IconButton } from "@mui/material";

import { styles } from "./MenuModal.styles";
import { useTranslation } from "react-i18next";
import { useAppNavigation } from "../../../context/AppNavigationContext";
import FooterBackground from "../../../assets/imgs/footer_background_img.png";
import LogoPurple from "../../../assets/imgs/logomark_purple.svg";
import CloseIcon from "../../../assets/icons/close_icon.svg";
import { PageBlockId, Paths } from "../../../shared/constants";
import ButtonText from "../../../design/button-text/ButtonText";

interface MenuModalProps {
  close: () => void;
}

const MenuModal: React.FC<MenuModalProps> = (props) => {
  const { close } = props;
  const { scrollToBlock } = useAppNavigation();
  const { t } = useTranslation();

  function headerClickHandler(block: PageBlockId) {
    close();
    scrollToBlock(Paths.home, block);
  }

  return (
    <div css={styles.menuModalWrapper}>
      <div css={styles.footerBackgroundWrapper}>
        <img src={FooterBackground} css={styles.footerBackground} alt={'Background'}/>
      </div>
      <div css={styles.menuModalContainer}>
        <LogoPurple/>
        <IconButton onClick={close}><CloseIcon/></IconButton>
        <div css={styles.menuModalContent}>
          <div>
            <h1 onClick={() => headerClickHandler(PageBlockId.aboutUs)}>{t("menuModal.menuModalAboutUs")}</h1>
            <h1 onClick={() => headerClickHandler(PageBlockId.projects)}>{t("menuModal.menuModalProjects")}</h1>
            <h1 onClick={() => headerClickHandler(PageBlockId.teams)}>{t("menuModal.menuModalTeams")}</h1>
            <h1 onClick={() => headerClickHandler(PageBlockId.contact)}>{t("menuModal.menuModalContact")}</h1>
            <div css={styles.menuModalLinksDivider}/>
            <ButtonText disableRipple>{t("menuModal.menuModalFacebook")}</ButtonText>
            <ButtonText disableRipple>{t("menuModal.menuModalLinkedin")}</ButtonText>
            <ButtonText disableRipple>{t("menuModal.menuModalInstagram")}</ButtonText>
          </div>
        </div>

      </div>
    </div>
  );
};

export default MenuModal;