import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import React from "react";
import { AppNavigationProvider } from "./context/AppNavigationContext";
import { Paths } from "./shared/constants";
import Projects from "./pages/Projects/Projects";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

interface AppRouterProps {
}

const AppRouter: React.FC<AppRouterProps> = (props: AppRouterProps) => {
  return <Router>
    <AppNavigationProvider>
      <Routes>
        <Route path={Paths.home} element={<Home/>}/>
        <Route path={Paths.projects} element={<Projects/>}/>
        <Route path={Paths.policy} element={<PrivacyPolicy/>}/>
        <Route path={'*'} element={<Navigate to={Paths.home}/>}/>
      </Routes>
    </AppNavigationProvider>
  </Router>
}

export default AppRouter;