import { css } from '@emotion/react';
import theme from "../../styles/theme";

export const styles = {
  privacyPolicyPageWrapper: css`
    padding: 50px;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 70px 70px 90px;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 70px 140px 90px;
    }

    & div{
      text-align: justify;
    }

    & h1, & h2, & h3, & h4, & h5, & h6 {
      color: ${theme.palette.primary.main};
      text-align: left;
    }

    & h2
    { margin-bottom: 30px;}

    & h3{
      margin: 20px 0 15px;
    }

    & p{
      font-size: 16px;
      font-weight: 400;
    }

    & ul li{
      text-align: left;
    }
  `
};