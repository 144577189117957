/** @jsxImportSource @emotion/react */
import React from "react";

import PageContainer from "../../layout/page-container/PageContainer";
import AboutUs from "./AboutUs/AboutUs";
import Projects from "./Projects/Projects";
import Teams from "./Teams/Teams";
import Location from "./Location/Location";
import Footer from "../../layout/footer/Footer";
import Contacts from "./Contacts/Contacts";
import { styles } from "./Home.styles";

interface HomeProps {
}

const Home: React.FC<HomeProps> = (props) => {
  return (
    <PageContainer customCss={styles.homePageWrapper}>
      <AboutUs/>
      <Projects/>
      <Teams/>
      <Location/>
      <Contacts/>
      <Footer/>
    </PageContainer>
  );
};

export default Home;