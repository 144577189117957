
const palette = {
  primary: {
    main: '#7654FD',
  },
  secondary: {
    main: '#F25416',
  },
  text:{
    primary: '#2C2C39',
    secondary: '#F0F0F0'
  },
  background: {
    default: '#FAFAFA',
    paper: '#ffffff'
  }
};

export default palette;