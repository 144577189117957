import { css } from '@emotion/react';
import theme from "../../../styles/theme";

export const styles = {
  contactsWrapper: css`
    padding: 50px 0;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 50px 70px 0 70px;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      padding-top: 0;
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      padding: 0 140px 50px;
    }
  `,
  contactsHeaderWrapper: css`
    & > h5 {
      margin-left: 50px;
      /*width: 100%;*/
      border-bottom: 1px solid ${theme.palette.primary.main};
      text-transform: uppercase;
      color: ${theme.palette.primary.main};
      margin-bottom: 58px;
      @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-left: 0;
      }
    }
  `,
  contactsContainer: css`
    display: flex;
    flex-direction: column;
    gap: 70px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > h2 {
        margin-top: 32px;
        color: ${theme.palette.primary.main};
      }

      & > p {
        font: normal normal 300 20px/30px Outfit;
        max-width: 330px;
        text-align: center;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: row;
      justify-content: space-around;
      gap: 0;
      max-width: 100%;

      & > div {
        flex: 1;
        align-items: flex-start;

        & > p {
          text-align: left;
        }
      }
    }
  `
}