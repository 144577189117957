import { css } from '@emotion/react';
import theme from "../../../styles/theme";

export const styles = {
  locationWrapper: css`
    position: relative;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 70px;
    }
  `,
  topPartContainer: css`
    position: relative;

    & > h1 {
      color: ${theme.palette.primary.main};
      max-width: 370px;
      position: absolute;
      right: 50px;
      left: 50px;
      top: 56px;

      @media (min-width: ${theme.breakpoints.values.xl}px) {
        left: 140px;
      }

  `,
  headerWrapper: css`
    display: none;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 50px;
      margin-top: 70px;
    }


    @media (min-width: ${theme.breakpoints.values.xl}px) {
      margin-right: 140px;
      margin-top: 140px;
    }

    & > div {
      width: 64px;
      border-top: 1px solid ${theme.palette.primary.main};
    }

    & > p {
      font: normal normal 300 24px/30px Outfit;
      margin: 0;
    }
  `,
  touchImage: css`
    margin-top: 132px;
    width: 100%;
  `,
  numbersWrapper: css`
    position: relative;
    width: 100%;
    height: 500px;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      height: unset;
    }

  `,
  numbersContainer: css`
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      flex-direction: row;
      justify-content: space-around;
      padding: 0 10%;
      top: -170px
    }
  `,
  numberContainer: css`
    & p {
      margin: 0;
    }

    & > div {
      display: flex;

      & > p:first-child {
        color: ${theme.palette.primary.main};
        font: normal normal bold 100px/100px Outfit;
      }

      & > p:last-child {
        color: ${theme.palette.secondary.main};
        font: normal normal 300 50px / 30px Outfit;
      }
    }

    & > p {
      font: normal normal 300 20px / 24px Outfit;
    }
  `,
  mapContainer: css`
    margin: 80px 50px 0;
    position: relative;

    & > p, & > h2, & > h4, & > ul, & > li {
      position: relative;
    }
    
    & > img {
      display: none;

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        display: unset;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    & > h2, & > h4 {
      color: ${theme.palette.primary.main};
    }

    & > ul {
      margin: 8px 0 0;
      list-style-type: none;
      padding: 0;

      @media (min-width: ${theme.breakpoints.values.lg}px) {
        list-style-type: unset;
        padding-left: 16px;
      }

      & > li {
        font: normal normal 300 14px/20px Outfit;

        @media (min-width: ${theme.breakpoints.values.lg}px) {
          max-width: 450px;

          &::marker {
            color: ${theme.palette.primary.main} !important;
          }
        }
      }
    }

    & > button {
      margin-top: 32px;
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      margin: 80px 70px 0;
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      margin-top: 0;
      height: 790px;

      & > h2 {
        padding-top: 192px;
      }

      & > p {
        max-width: 340px
      }
    }

    @media (min-width: ${theme.breakpoints.values.xl}px) {
      margin: 0 140px;

    }
  `,

}