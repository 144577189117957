/** @jsxImportSource @emotion/react */
import React from "react";

import { styles } from "./Contacts.styles";
import { useTranslation } from "react-i18next";
import { PageBlockId } from "../../../shared/constants";
import ContactImage from '../../../assets/imgs/contact_phone_img.svg';
import EmailImage from '../../../assets/imgs/contact_email_img.svg';
import LocationImage from '../../../assets/imgs/contact_location_img.svg';

interface ContactsProps {
}

const Contacts: React.FC<ContactsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <section id={PageBlockId.contact} css={styles.contactsWrapper}>
      <div css={styles.contactsHeaderWrapper}>
        <h5>{t("locationPage.letsGetToWorkTitle")}</h5>
      </div>
      <div css={styles.contactsContainer}>
        <div>
          <ContactImage/>
          <h2>{t('locationPage.phoneTitle')}</h2>
          <p>{t('locationPage.phoneValue')}</p>
        </div>
        <div>
          <EmailImage/>
          <h2>{t('locationPage.emailTitle')}</h2>
          <p>{t('locationPage.emailValue')}</p>
        </div>
        <div>
          <LocationImage/>
          <h2>{t('locationPage.addressTitle')}</h2>
          <p>{t('locationPage.addressValue')}</p>
          <p>{t('locationPage.addressValue2')}</p>
        </div>
      </div>
    </section>
  );
}

export default Contacts;