export enum Language {
  en = "en",
  pl = "pl"
}

export const availableLanguages: Array<Language> = [Language.en, Language.pl];
export const defaultLanguage: Language = Language.en;

export enum PageBlockId {
  aboutUs = "aboutUs",
  projects = "projects",
  teams = "teams",
  contact = "contact",
}

export enum Paths {
  home = '/',
  projects = '/projects',
  policy = '/policy',

}