/** @jsxImportSource @emotion/react */
import React from "react";
import config from "../../config.json";

import { styles } from "./Footer.styles";
import { useTranslation } from "react-i18next";

import FooterBackground from '../../assets/imgs/footer_background_img.png'
import LogoPurple from '../../assets/imgs/logomark_purple.svg'
import ButtonText from "../../design/button-text/ButtonText";
import { PageBlockId, Paths } from "../../shared/constants";
import { useAppNavigation } from "../../context/AppNavigationContext";
import { Link } from "react-router-dom";


interface FooterProps {
}

const Footer: React.FC<FooterProps> = (props) => {
  const { scrollToBlock } = useAppNavigation();

  const { t } = useTranslation();

  return (
    <footer css={styles.footerWrapper}>
      <div css={styles.footerBackgroundWrapper}>
        <img src={FooterBackground} css={styles.footerBackground} alt={'Background'} />
      </div>
      <div css={styles.footerContent}>
        <div css={styles.footerLeftContent}>
          <LogoPurple />
          <p>
            {t('footer.footerDescription')}
          </p>
        </div>
        <div css={styles.footerRightContent}>
          <div>
            <h4>{t("footer.mapSiteTitle")}</h4>
            <ButtonText
              disableRipple
              onClick={() => scrollToBlock(Paths.home, PageBlockId.aboutUs)}>
              {t("footer.mapSiteAboutUs")}</ButtonText>
            <ButtonText
              disableRipple
              onClick={() => scrollToBlock(Paths.home, PageBlockId.projects)}>
              {t("footer.mapSiteProjects")}
            </ButtonText>
            <ButtonText
              disableRipple
              onClick={() => scrollToBlock(Paths.home, PageBlockId.teams)}>
              {t("footer.mapSiteTeams")}
            </ButtonText>
            <ButtonText
              disableRipple
              onClick={() => scrollToBlock(Paths.home, PageBlockId.contact)}>
              {t("footer.mapSiteContact")}
            </ButtonText>
          </div>
          {/* <div>
            <h4>{t("footer.workWithUsTitle")}</h4>
            <ButtonText disableRipple>{t("footer.workWithUsJoinOurTeam")}</ButtonText>
          </div> */}
          {config.socialLinks.active && config.socialLinks.links.some( link=>link.active) ?
            <div>
              <h4>{t("footer.socialTitle")}</h4>
              {config.socialLinks.links.filter(l => l.active).map(link => (<ButtonText disableRipple onClick={() => window.open(link.url, '_blank')}>{t(link.i18n_key)}</ButtonText>))}

              {/* <ButtonText disableRipple onClick={() => window.open('https://www.facebook.com/people/Breakthrough-Technologies/61565132735773/', '_blank')}>{t("footer.socialFacebook")}</ButtonText>
              <ButtonText disableRipple onClick={() => window.open('https://www.linkedin.com/company/102063851', '_blank')}>{t("footer.socialLinkedin")}</ButtonText>
              <ButtonText disableRipple onClick={() => window.open('https://www.instagram.com/breakthrough_technologies/', '_blank')}>{t("footer.socialInstagram")}</ButtonText> */}
            </div> : ("")}
        </div>
      </div>
      <div css={styles.footerPolicy}>
        <span>{t("footer.allRightsReservedLink")}</span>
        <span css={styles.footerPolicyDivider}>|</span>
        <Link to={Paths.policy}>{t("footer.privacyPolicyLink")}</Link>
      </div>
    </footer>
  );
};

export default Footer;