import { css } from '@emotion/react';
import theme from "../../../styles/theme";

export const styles = {
  menuModalWrapper: css`
    background: #2C2C39 0% 0% no-repeat padding-box;
    padding: 70px 60px 300px 130px;
    width: 100%;
    min-height: 100%;
    position: relative;
    
    @media (max-width: ${theme.breakpoints.values.md}px) {
      padding: 30px 30px 250px;
    }
  `,
  footerBackgroundWrapper: css`
    position: absolute;
    bottom: 0;
    right: 0;
    min-height: 470px;
    overflow: hidden;
    width: 100%;
  `,
  footerBackground: css`
    position: absolute;
    min-height: 470px;
    width: 100%;
    object-fit: cover;
    object-position: right;
  `,
  menuModalContainer: css`
    width: 100%;
    position: relative;

    & > svg {
      position: absolute;
      top: -40px;
      left: -26px;
      transform: scale(0.5);

      @media (max-width: ${theme.breakpoints.values.md}px) {
        top: 10px;
        left: calc(50% - 55px);
      }
    }
  ,
  & > button {
    position: absolute;
    right: 0;
    top: 20px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      top: 0;
   }
  }
  `,
  menuModalContent: css`
    display: flex;
    justify-content: center;
    padding-top: 210px ;


    & > div {      
      & > h1 {
        color: ${theme.palette.primary.main};
        transition: color 0.3s linear;
        cursor: pointer;
        font: normal normal bold 40px/80px Outfit;
        
        &:hover {
          color: ${theme.palette.secondary.main};
        }
      }

      & > h1 + h1{
        margin-top: 6px;
      }
      
      & > button + button {
        margin-top: 24px;
      }

      & > button {
        display: block;
        color: ${theme.palette.text.secondary};
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        text-transform: uppercase;
        justify-content: flex-start;
        font: normal normal 300 20px/20px Outfit;
      }
    }
  `,
  menuModalLinksDivider: css`
    margin: 86px  0 26px;
    width: 48px;
    border-top: 1px solid ${theme.palette.primary.main};
  `,
};