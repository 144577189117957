/** @jsxImportSource @emotion/react */
import React from "react";

import PageContainer from "../../layout/page-container/PageContainer";
import { styles } from "./PrivacyPolicy.styles";
import Footer from "../../layout/footer/Footer";
import { useTranslation } from "react-i18next";

interface ProjectsProps {
}

const PrivacyPolicy: React.FC<ProjectsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <section css={styles.privacyPolicyPageWrapper}>
        <div dangerouslySetInnerHTML={{ __html: t('privacyPolicyPage.htmlContent')} } />
      </section>
      <Footer/>
    </PageContainer>
  );
};

export default PrivacyPolicy;