import { css } from '@emotion/react';

// set it 170 when headerTopInfo block in header will be uncommented
// const headerHeight = '170px'
const headerHeight = '80px'

export const styles = {
  pageWrapper: css`
    height: 100vh;
    //max-width: 1440px;
    //width: 1440px;
    width: 100%;

    @supports (height: 1dvh) {
      height: 100dvh;
    }
  `,
  pageContainerWrapper: css`
    height: calc(100vh - ${headerHeight});
    overflow-y: auto;

    @supports (height: 1dvh) {
      height: calc(100dvh - ${headerHeight})
    }
  `
};