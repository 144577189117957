import { css } from '@emotion/react';
import theme from "../../../../styles/theme";

export const styles = {
  expandableInfoParagraphWrapper: css`
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      gap: 24px;
    }

      & > svg {
      min-width: 32px;
    }
  `,
  expandableInfoParagraphHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h2 {
      color: ${theme.palette.primary.main};
      text-transform: uppercase;
    }
  `,
  arrowIconButton: css`
    transition: transform 0.3s ease;
  `,
  revertedArrowIcon: css`
    transform: rotate(180deg);
  `,
  expandableInfoParagraphContent: css`
    flex: 1;

    & > p {
      margin: 8px 0 16px;
      height: 0;
      overflow-y: hidden;
      font: normal normal 300 14px/20px Outfit;
      transition: height 0.3s ease;
    }
  `,
  expandableInfoParagraphFooter: css`
    width: 100%;
    border-bottom: 1px solid ${theme.palette.primary.main};
    margin-bottom: 64px;
  `
}