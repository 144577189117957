import React, { useState } from 'react';

import { PageBlockId } from "../shared/constants";
import { useNavigate } from "react-router-dom";

const AppNavigationContext = React.createContext <{
  activeBlock: PageBlockId | null;
  scrollToBlock: (path: string, block: PageBlockId) => void;
}>({
  activeBlock: null,
  scrollToBlock: (path: string, block: PageBlockId) => {
  }
});

interface AppGlobalProviderProps {
  children: React.ReactNode
}

function AppNavigationProvider(props: AppGlobalProviderProps) {
  const [activeBlock, setActiveBlock] = useState<PageBlockId | null>(null);
  const navigate = useNavigate();

  function scrollToBlock(path: string, blockId?: PageBlockId) {
    if (path) {
      navigate(path);
    }

    if (blockId) {
      setTimeout(() => {
        const element = document.getElementById(blockId);

        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" })
          setActiveBlock(blockId)
        }
      }, 200);
    }
  }

  return (
    <AppNavigationContext.Provider
      value={{
        activeBlock,
        scrollToBlock
      }}>
      {props.children}
    </AppNavigationContext.Provider>
  );
}

function useAppNavigation() {
  const context = React.useContext(AppNavigationContext);
  if (context === undefined) {
    throw new Error('useAppNavigation must be used within an AppNavigationProvider');
  }

  return context;
}

export { useAppNavigation, AppNavigationProvider };
