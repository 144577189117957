/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import { styles } from "./Projects.styles";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";

import projectImage1 from '../../../assets/imgs/project_img_1.png';
import projectImage2 from '../../../assets/imgs/project_img_2.png';
import projectImage3 from '../../../assets/imgs/project_img_3.png';
import projectImage4 from '../../../assets/imgs/project_img_4.png';
import ArrowLeft from '../../../assets/icons/arrow_left_icon.svg'
import ArrowRight from '../../../assets/icons/arrow_right_icon.svg'
import ButtonSecondary from "../../../design/button-secondary/ButtonSecondary";
import { PageBlockId, Paths } from "../../../shared/constants";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";

interface ProjectsProps {
}

const Projects: React.FC<ProjectsProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFading, setIsFading] = useState(false);
  const [projects] = useState([
    {
      id: 1,
      img: projectImage1,
      title: t('projectsPage.projects.0.title'),
      headerLine1: t('projectsPage.projects.0.headerLine1'),
      headerLine2: t('projectsPage.projects.0.headerLine2'),
      headerLine3: t('projectsPage.projects.0.headerLine3'),
      challengesDescription: t('projectsPage.projects.0.challengesDescription'),
      solutionsDescription: t('projectsPage.projects.0.solutionsDescription'),
    },
    {
      id: 2,
      img: projectImage2,
      title: t('projectsPage.projects.1.title'),
      headerLine1: t('projectsPage.projects.1.headerLine1'),
      headerLine2: t('projectsPage.projects.1.headerLine2'),
      headerLine3: t('projectsPage.projects.1.headerLine3'),
      challengesDescription: t('projectsPage.projects.1.challengesDescription'),
      solutionsDescription: t('projectsPage.projects.1.solutionsDescription'),
    },
    {
      id: 3,
      img: projectImage3,
      title: t('projectsPage.projects.2.title'),
      headerLine1: t('projectsPage.projects.2.headerLine1'),
      headerLine2: t('projectsPage.projects.2.headerLine2'),
      headerLine3: t('projectsPage.projects.2.headerLine3'),
      challengesDescription: t('projectsPage.projects.2.challengesDescription'),
      solutionsDescription: t('projectsPage.projects.2.solutionsDescription'),
    },
    {
      id: 4,
      img: projectImage4,
      title: t('projectsPage.projects.3.title'),
      headerLine1: t('projectsPage.projects.3.headerLine1'),
      headerLine2: t('projectsPage.projects.3.headerLine2'),
      headerLine3: t('projectsPage.projects.3.headerLine3'),
      challengesDescription: t('projectsPage.projects.3.challengesDescription'),
      solutionsDescription: t('projectsPage.projects.3.solutionsDescription'),
    }
  ]);
  const [selectedProject, setSelectedProject] = useState(projects[0]);

  function changeProject(newIndex: number) {
    setIsFading(true);

    setTimeout(() => {
      setSelectedProject(projects[newIndex]);
      setIsFading(false);
    }, 300);
  }

  function nextProject() {
    const currentIndex = projects.findIndex((p) => p.id === selectedProject.id);
    const newIndex = currentIndex >= projects.length - 1 ? 0 : currentIndex + 1;
    changeProject(newIndex);
  }

  function prevProject() {
    const currentIndex = projects.findIndex((p) => p.id === selectedProject.id);
    const newIndex = currentIndex <= 0 ? projects.length - 1 : currentIndex - 1;
    changeProject(newIndex);
  }

  return (
    <section css={styles.projectsWrapper} id={PageBlockId.projects}>
      <div css={styles.projectsHeaderWrapper}>
        <h5 css={styles.projectsHeader}>{t("projectsPage.projectsTitle")}</h5>
        <div css={styles.projectsInfo}>
          <h2>{t("projectsPage.successStoriesTitle")}</h2>
          <p>{t("projectsPage.successStoriesDescription")}</p>
        </div>
      </div>
      <div css={styles.projectsContainer}>
        <IconButton onClick={prevProject}><ArrowLeft/></IconButton>
        <div css={css(styles.projectWrapper, isFading && styles.projectWrapperFadeOut)}>
          <img src={selectedProject.img} alt="Project"/>
          <div css={styles.projectDataWrapper}>
            <div css={styles.projectDataHeader}>
              <p>{selectedProject.headerLine1}</p>
              <p>{selectedProject.headerLine2}</p>
              <p>{selectedProject.headerLine3}</p>
            </div>
            <div css={styles.projectDataHeaderUnderline}/>
            <div css={styles.projectDescriptionWrapper}>
              <h2>{selectedProject.title}</h2>
              <h4>{t('projectsPage.challengesTitle')}</h4>
              <p>{selectedProject.challengesDescription}</p>
              <h4>{t('projectsPage.solutionsTitle')}</h4>
              <p>{selectedProject.solutionsDescription}</p>
            </div>
          </div>
          <div css={styles.projectNumber}>#<span>{selectedProject.id}</span></div>
        </div>
        <IconButton onClick={nextProject}><ArrowRight/></IconButton>
      </div>
      {/*<div css={styles.buttonContainer}>*/}
      {/*  <ButtonSecondary onClick={() => navigate(Paths.projects)}>{t("projectsPage.allProjectsTitle")}</ButtonSecondary>*/}
      {/*</div>*/}
    </section>
  );
}

export default Projects;