/** @jsxImportSource @emotion/react */
import React from "react";
import { Button, IconButton, Modal } from "@mui/material";

import { styles } from "./Header.styles";
import LogoMenu from '../../assets/imgs/logo_menu.svg'
import MenuIcon from '../../assets/icons/menu_icon.svg'
import { useTranslation } from "react-i18next";
import ButtonText from "../../design/button-text/ButtonText";
import { PageBlockId, Paths } from "../../shared/constants";
import { useAppNavigation } from "../../context/AppNavigationContext";
import MenuModal from "./MenuModal/MenuModal";

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = (props) => {
  const { scrollToBlock, activeBlock } = useAppNavigation();
  const { t } = useTranslation();
  // const { setLanguage, nexLanguage } = useAppGlobal();

  const [modalOpen, setModalOpen] = React.useState(false);

  function handleModalOpen() {
    setModalOpen(true);
  }

  function handleClose() {
    setModalOpen(false);
  }

  return (
    <header css={styles.headerWrapper}>
      {/*<div css={styles.headerTopInfo}>*/}
      {/*  {t("header.letsStartWorkingMessage")}*/}
      {/*  <Button*/}
      {/*    variant="text"*/}
      {/*    css={styles.briefUsButton}*/}
      {/*  >*/}
      {/*    {t("header.letsStartWorkingButton")}*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div css={styles.headerPanelWrapper}>
        <div>
          <a href="/"><LogoMenu/></a>
        </div>
        <div css={styles.navigationWrapper}>
          <ButtonText
            styles={styles.navigationButton}
            active={activeBlock === PageBlockId.aboutUs}
            onClick={() => scrollToBlock(Paths.home, PageBlockId.aboutUs)}>
            {t("header.aboutUsButton")}
          </ButtonText>
          <ButtonText
            styles={styles.navigationButton}
            active={activeBlock === PageBlockId.projects}
            onClick={() => scrollToBlock(Paths.home, PageBlockId.projects)}>
            {t("header.projectsButton")
            }</ButtonText>
          <ButtonText
            styles={styles.navigationButton}
            active={activeBlock === PageBlockId.teams}
            onClick={() => scrollToBlock(Paths.home, PageBlockId.teams)}>
            {t("header.teamsButton")}
          </ButtonText>
          <ButtonText
            styles={styles.navigationButton}
            active={activeBlock === PageBlockId.contact}
            onClick={() => scrollToBlock(Paths.home, PageBlockId.contact)}>
            {t("header.contactButton")}
          </ButtonText>
          <div css={styles.menuAndLanguageWrapper}>
            {/*<Button*/}
            {/*  css={styles.languageButton}*/}
            {/*  variant="contained" color="primary"*/}
            {/*  onClick={() => setLanguage(nexLanguage)}>*/}
            {/*  {t(`header.switchToLanguageButton_${nexLanguage}`)}*/}
            {/*</Button>*/}
            <IconButton css={styles.menuIconButton} onClick={handleModalOpen}>
              <MenuIcon/>
            </IconButton>
          </div>
        </div>
      </div>
      <Modal
        css={styles.menuModal}
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MenuModal close={handleClose}/>
      </Modal>
    </header>
  );
};

export default Header;